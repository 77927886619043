<template>
  <div class="mt-3 p-2">
    <b-container fluid class="mb-2">
      <b-row class="mb-3">
        <b-col cols="11">
          <h5 class="text-uppercase heading">Trees View</h5>
        </b-col>
        <b-col cols="1" class="d-flex justify-content-end">
          <b-button variant="success" @click="downloadCSV">
            <i class="fa fa-download" aria-hidden="true"></i>
          </b-button>
        </b-col>
      </b-row>
      <b-card class="mb-3">
        <b-card-text class="d-flex justify-content-between align-items-center">
          <h1 class="d-flex align-items-end">
            Total Trees
            <span class="h5 ml-2"> (Sum of trees including all farms)</span>
          </h1>
          <h1>{{ totalPlants }}</h1>
          <div @click="showDetails(totalItems)">
            <i
              class="fas fa-eye view-icon"
              style="height: 50px"
              aria-hidden="true"
            ></i>
          </div>
        </b-card-text>
      </b-card>
      <b-table
        class="dataTable rounded-left"
        :outlined="true"
        :items="items"
        :fields="fields"
        head-variant="none"
        table-variant="none"
        :busy="isBusy"
      >
        <template #cell(#)="data">
          <span>{{ data.index + 1 }}</span>
        </template>
        <template #cell(treesCount)="data">
          <span>{{ data.item.trees_count }}</span>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(actions)="row">
          <div @click="showDetails(row.item.project_assets)">
            <i class="fas fa-eye view-icon" aria-hidden="true"></i>
          </div>
        </template>
      </b-table>

      <b-modal
        size="xl"
        hide-footer
        title="Project Assets"
        id="project-assets-data"
      >
        <template v-if="assetDetails.length > 0">
          <b-table
            striped
            small
            class="dataTable"
            sticky-header="80vh"
            :fields="assetFields"
            :items="assetDetails"
          >
            <template #cell(#)="data">
              <span>{{ data.index + 1 }}</span>
            </template>
          </b-table>
        </template>

        <b-row v-else>
          <b-col cols="12">
            <p class="text-muted">
              Sorry! No Assets data available for this project!
            </p>
          </b-col>
        </b-row>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'TreeView',
  data() {
    return {
      fields: [
        '#',
        {
          key: 'project.name',
          label: 'Farm Name',
          sortable: true,
        },
        {
          key: 'trees_count',
          label: 'Trees Count',
          sortable: true,
        },
        'actions',
      ],
      items: [],
      assetFields: [
        '#',
        {
          key: 'asset_name',
          sortable: true,
        },
        {
          key: 'quantity',
          sortable: true,
        },
      ],
      assetDetails: [],
      totalItems: [],
      totalPlants: '',
      isBusy: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isBusy = true;
      window.axios.get('projects/assets').then((res) => {
        this.items = res.data.data.projectAssets;
        this.totalItems = res.data.data.total_assets;
        this.totalPlants = res.data.data.total_plants;

        console.log(res.data);
        this.isBusy = false;
      });
    },

    showDetails(assets) {
      this.assetDetails = assets;
      this.$bvModal.show('project-assets-data');
    },

    downloadCSV() {
      let csv = '\ufeff' + 'FARM NAME,TREES COUNT\n';
      this.items.forEach((el) => {
        var line = el['project'].name + ',' + el['trees_count'] + '\n';
        csv += line;
      });
      var blob = new Blob([csv], { type: 'csv/plain' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Trees.csv';
      link.click();
    },
  },
};
</script>

<style scoped>
.view-icon {
  color: #1da040;
  height: 20px;
  width: 30px;
}

/deep/ .dataTable thead {
  background-color: #dcffd9;
  height: 64px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 3.5%;
  font-family: 'Poppins', sans-serif;
  padding-top: 14px;
  padding-bottom: 14px;
  color: #303030;
}

/deep/ .dataTable {
  text-align: center;
  margin-bottom: 21.5px;
  margin-left: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

/deep/ .dataTable tr td {
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  letter-spacing: 3.5%;
  line-height: 25.5px;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
}
</style>
